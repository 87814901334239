import smoothScroll from "smoothscroll-polyfill"
import WithContext from "./WrapRootElement"
import WithAnimationAndCookies from "./WrapPageElement"
import "./src/styles/index.scss"

export const onClientEntry = () => {
    smoothScroll.polyfill();
}

export const wrapRootElement = WithContext
export const wrapPageElement = WithAnimationAndCookies

