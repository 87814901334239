import * as React from "react"
import { Dispatch, FunctionComponent, memo, useContext, useEffect, useReducer } from "react"
import { useCheckForScreens } from "./utilities";

interface IAppState extends IReducer {
    screen: string
    isMobile: boolean
    dispatch: Dispatch<ActionType>
}

interface IReducer {
    drawerOpen: boolean
}

type ActionType = {
    type: 'TOGGLE_DRAWER' | 'CLOSE_DRAWER'
    args?: any
}

function appStateReducer( state: IReducer, action: ActionType ) {
    switch (action.type) {
        case 'TOGGLE_DRAWER':
            return {
                ...state,
                drawerOpen: !state.drawerOpen
            }
        case 'CLOSE_DRAWER':
            return {
                ...state,
                drawerOpen: false
            }
        default:
            throw new Error();
    }
}


export const AppState = React.createContext<IAppState>( {
    drawerOpen: false,
    screen    : 'desktop',
    isMobile  : false,
    dispatch  : () => {
    }
} );

export const AppStateProvider: FunctionComponent = memo( ( { children } ) => {
    const { screen, isMobile } = useCheckForScreens()
    const [ state, dispatch ] = useReducer( appStateReducer, {
        drawerOpen: false,
    } );
    useEffect( () => {
        if (!isMobile) {
            dispatch( { type: "CLOSE_DRAWER" } )
        }
    }, [ isMobile ] )
    return (
        <AppState.Provider value={ {
            drawerOpen: state.drawerOpen,
            screen, isMobile,
            dispatch  : dispatch
        } }>
            { children }
        </AppState.Provider>
    )
} )

export function useAppState() {
    return useContext( AppState )
}
