import React from "react";
import { AccessibilityStateProvider } from "./src/accessibility/AccessibilityContext";
import { AppStateProvider } from "./src/AppState";
import { GatsbyBrowser } from "gatsby";

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ( { element } ) => {
    return (

        <AccessibilityStateProvider>
            <AppStateProvider>
                { element }
            </AppStateProvider>
        </AccessibilityStateProvider>
    )
};

export default wrapRootElement
