import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DOMPurify from 'isomorphic-dompurify';

//-------------------------------------------------------------------------------//
// Sanitize HTML
//-------------------------------------------------------------------------------//
export function sanitizedHTML( html: string ) {
    return { __html: DOMPurify.sanitize( html, { USE_PROFILES: { html: true }, ADD_ATTR: [ 'target', 'd', 'viewBox', 'fill' ], ADD_TAGS: [ "svg", "path" ] } ) }
}

//-------------------------------------------------------------------------------//
// String utils
//-------------------------------------------------------------------------------//
export function joinIgnoreEmpty( ...strings: any ) {
    return strings.filter( Boolean ).join( " " );
}

export function createLabel( name: string, operator: string = "-" ) {
    const addSpace = name.split( operator ).join( " " );
    return addSpace.charAt( 0 ).toUpperCase() + addSpace.substring( 1 ).toLowerCase();
}

export function imgAlt( imgSrc: string, after?: string ) {
    return imgSrc.split( "/" ).pop()?.replace( /.png|.jpg/g, "" ) + (after ? " " + after : "")
}

//-------------------------------------------------------------------------------//
// Get browser language
//-------------------------------------------------------------------------------//
export function getBrowserLanguage() {
    if (typeof navigator === `undefined`) {
        return "en";
    }
    const lang = navigator?.language?.split( "-" )[0];
    if (!lang) return "en";
    switch (lang) {
        case "el":
            return "el";
        default:
            return "en";
    }
}

//-------------------------------------------------------------------------------//
// Theme Changer
//-------------------------------------------------------------------------------//
export function useThemeChanger(): [ string, Dispatch<SetStateAction<string>> ] {
    const [ theme, setTheme ] = useState( "" );

    useEffect( () => {
        let currentTheme = localStorage.getItem( "currentTheme" );
        if (!currentTheme) {
            if (window.matchMedia && window.matchMedia( '(prefers-color-scheme: dark)' ).matches) {
                currentTheme = "dark";
            } else {
                currentTheme = "light";
            }
        }
        setTheme( currentTheme )
    }, [] )

    return [ theme, setTheme ]
}

export type ScreenBreakpoint = {
    phone?: number
    tablet?: number
    laptop?: number
    desktop?: number
}

export const useCheckForScreens = ( breakPoints?: ScreenBreakpoint ): { screen: string, isMobile: boolean, } => {
    const [ isMobile, setIsMobile ] = useState( false );
    const [ screen, setScreen ] = useState( "" );

    function checker() {
        const phone = breakPoints?.phone ? breakPoints.phone : 480;
        const tablet = breakPoints?.tablet ? breakPoints.tablet : 768;
        const laptop = breakPoints?.laptop ? breakPoints.laptop : 992;
        const desktop = breakPoints?.desktop ? breakPoints.desktop : 1200;
        let windowWidth = document.documentElement.clientWidth;
        if (windowWidth < phone) {
            setIsMobile( true );
            setScreen( 'phone' );
        } else if (windowWidth <= tablet) {
            setIsMobile( true );
            setScreen( 'tablet' )
        } else if (windowWidth < laptop) {
            setIsMobile( false );
            setScreen( 'laptop' )
        } else if (windowWidth >= desktop) {
            setIsMobile( false );
            setScreen( 'desktop' )
        }
    }

    useEffect( () => {
        checker();
        window.addEventListener( 'resize', checker );
        return () => {
            checker();
            window.removeEventListener( 'resize', checker );
        };
    } );

    return { isMobile, screen }
};

export function smoothScroll( id: string, offset: number = 90 ) {
    const element = document.getElementById( id );
    if (!element) {
        return
    }
    const top = window.scrollY + element.getBoundingClientRect().top - offset;
    window.scrollTo( {
        behavior: 'smooth',
        top
    } );
}
