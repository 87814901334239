import React from "react";
import { AnimatePresence } from "framer-motion";
import { GatsbyBrowser, Script } from "gatsby";

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ( { element, props } ) => {
    return (
        <>
            { process.env.GATSBY_GOOGLE_RECAPTACH_SITE_KEY &&
                <Script key={ "recaptcha-script" } src={ `https://www.google.com/recaptcha/api.js?render=${ process.env.GATSBY_GOOGLE_RECAPTACH_SITE_KEY }` }/>
            }
            <AnimatePresence exitBeforeEnter>
                <div key={ props.location.pathname }>
                    { element }
                </div>
            </AnimatePresence>
        </>
    )
}

export default wrapPageElement
