import * as React from "react"
import { Dispatch, FunctionComponent, memo, useContext, useEffect, useReducer } from "react"
import Cookies from 'js-cookie';

interface IAccessibilityState extends IReducer {
    dispatch: Dispatch<ActionType>
}

interface IReducer {
    isOpen: boolean
    theme?: string
    colorBlind: boolean
    fontSize?: number
}

type ActionType = {
    type: 'ACC_INIT' | 'INC_FONT' | 'DEC_FONT' | 'RESET_FONT' | 'OPEN_TOOLS' | 'CLOSE_TOOLS' | 'TOGGLE_TOOLS' | 'TOGGLE_COLORBLIND' | 'TOGGLE_THEME'
    args?: any
}

function accessibilityStateReducer( state: IReducer, action: ActionType ) {
    const style = window.getComputedStyle( document.documentElement, null ).getPropertyValue( 'font-size' );
    const cookieFontSize = Cookies.get( "acc-font-size" );
    const docFontSize = cookieFontSize ? parseFloat( cookieFontSize ) : parseFloat( style );
    let fontSize: number;
    let currentTheme = localStorage.getItem( "color-theme" );
    switch (action.type) {
        case 'ACC_INIT':
            const isColorBlind = Cookies.get( "acc-colorblind" ) === "true"
            cookieFontSize && (document.documentElement.style.fontSize = `${ parseFloat( cookieFontSize ) }px`);
            isColorBlind && document.body.classList.add( "grayscale" );
            if (!currentTheme) {
                if (window.matchMedia && window.matchMedia( '(prefers-color-scheme: dark)' ).matches) {
                    currentTheme = "dark";
                } else {
                    currentTheme = "light";
                }
            }
            document.body.dataset.theme = currentTheme
            localStorage.setItem( "color-theme", currentTheme )
            return {
                ...state,
                fontSize  : docFontSize,
                colorBlind: isColorBlind,
                theme     : currentTheme
            }
        case 'TOGGLE_TOOLS': {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }
        case 'OPEN_TOOLS': {
            return {
                ...state,
                isOpen: true
            }
        }
        case 'CLOSE_TOOLS': {
            return {
                ...state,
                isOpen: false
            }
        }
        case 'TOGGLE_THEME':
            currentTheme = state.theme === "dark" ? "light" : "dark"
            document.body.dataset.theme = currentTheme
            localStorage.setItem( "color-theme", currentTheme )
            return {
                ...state,
                theme: currentTheme
            };
        case 'INC_FONT':
            fontSize = docFontSize + 1;
            if (fontSize > 21) {
                return state
            }
            document.documentElement.style.fontSize = `${ fontSize }px`;
            Cookies.set( "acc-font-size", `${ fontSize }px`, { expires: 30 } )
            return {
                ...state,
                fontSize
            }
        case 'DEC_FONT':
            fontSize = docFontSize - 1;
            if (fontSize < 14) {
                return state
            }
            document.documentElement.style.fontSize = `${ fontSize }px`;
            Cookies.set( "acc-font-size", `${ fontSize }px`, { expires: 30 } )
            return {
                ...state,
                fontSize
            }
        case 'RESET_FONT':
            document.documentElement.style.fontSize = ``;
            Cookies.remove( "acc-font-size", { expires: 30 } )
            return {
                ...state,
                fontSize: undefined
            }
        case 'TOGGLE_COLORBLIND':
            const cb = !state.colorBlind
            if (cb) {
                document.body.classList.add( "grayscale" )
                Cookies.set( "acc-colorblind", "true", { expires: 30 } )
            } else {
                document.body.classList.remove( "grayscale" )
                Cookies.set( "acc-colorblind", "false", { expires: 30 } )
            }
            return {
                ...state,
                colorBlind: cb
            }
        default:
            throw new Error();
    }
}


export const AccessibilityState = React.createContext<IAccessibilityState>( {
    isOpen    : false,
    colorBlind: false,
    dispatch  : () => {
    }
} );

export const AccessibilityStateProvider: FunctionComponent = memo( ( { children } ) => {
    const [ state, dispatch ] = useReducer( accessibilityStateReducer, {
        isOpen    : false,
        colorBlind: false
    } );
    useEffect( () => {
        dispatch( { type: "ACC_INIT" } )
    }, [] )
    return (
        <AccessibilityState.Provider value={ {
            isOpen    : state.isOpen,
            colorBlind: state.colorBlind,
            fontSize  : state.fontSize,
            theme     : state.theme,
            dispatch  : dispatch
        } }>
            { children }
        </AccessibilityState.Provider>
    )
} )

export function useAccState() {
    return useContext( AccessibilityState )
}
